import React from 'react';
import TutorialDots from './TutorialDots';

const Tutorial = (props) => {
    const {
        elements,
        step,
        onClickRight,
        onClickLeft,
        onDotClick
    }= props

    return (
        <div className="tutorial-card position-relative">
            <div className="tutorial-button-container left"
                onClick={()=>{onClickLeft()}}
            >                
                <div className="tutorial-button p-3">
                    <h1 className="text-center no-margin">
                        <i className="fa fa-angle-left pe-1"></i>
                    </h1>
                </div>
            </div>
            <div className="tutorial-button-container right"
                onClick={()=>{onClickRight()}}
            >                
                <div className="tutorial-button p3">
                    <h1 className="text-center no-margin">
                        <i className="fa fa-angle-right ps-1"></i>
                    </h1>
                </div>
            </div>
            <TutorialDots 
                elements={elements}
                step={step}
                onItemClick={
                    (index)=>{
                        onDotClick(index)
                    }
                }
            />
            
            
            <div className='row w-100 justify-content-center justify-content-md-start mx-auto'>
                <div className="col-md-7">
                    <img src={elements[step].image} alt="" className="w-100" />
                </div>
                <div className="col-12 col-md-5 align-self-center text-white text-center">
                    <div className="btm-blue-div">
                        <div className="arrow">
                            
                        </div>
                        <h3 className=''>{elements[step].title}</h3>
                        <p>{elements[step].description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tutorial;
