import React from 'react';

import downloadAndroidPassenger from "./../../../assets/btn-pasajero-android.svg"
import downloadAndroidDriver from "./../../../assets/btn-conductor-android.svg"

import downloadIosPassenger from "./../../../assets/btn-pasajero-ios.svg"
import downloadIosDriver from "./../../../assets/btn-conductor-ios.svg"
import { useTranslation } from 'react-i18next';

import C from '../../../constants.js';


const DownloadModal = (props) => {
    const {
        brand,
        onClose
    } = props

    const {t} = useTranslation()

    const variables = {
        "linkDriverIos":C.links.iosDriver,
        "linkPassengerIos":C.links.iosPassenger,
        "linkDriverAndroid":C.links.androidDriver,
        "linkPassengerAndroid":C.links.androidPassenger,
    }

    return (
        <div className='modal-download-container'>
            <div className="modal-download">
                <div className="modal-download-close" onClick={()=>{onClose()}}>                    
                    X                  
                </div>                
                <div className="modal-download-body">
                    <div className="row justify-content-center">
                        <div className="col-12 my-5">
                            <h1 className="header-blue text-center">
                                { t('GENERAL_download') }
                            </h1>
                        </div>
                        <div className="col-md-5 col-6 text-center">
                            <a target="_blank" href={brand=="android"?variables.linkPassengerAndroid:variables.linkPassengerIos}>
                                <img src={brand=="android"?downloadAndroidPassenger:downloadIosPassenger} alt="" className="w-75" />
                            </a>
                        </div>
                        <div className="col-md-5 col-6 text-center">
                            <a target="_blank" href={brand=="android"?variables.linkDriverAndroid:variables.linkDriverIos}>
                                <img src={brand=="android"?downloadAndroidDriver:downloadIosDriver} alt="" className="w-75" />
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default DownloadModal;
