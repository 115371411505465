import React from 'react';
import { useTranslation } from 'react-i18next';

const UserTypeSwitch = (props) => {
    const {t} = useTranslation()
    const {
        selected='passenger',
        onChange,
        inverted=false
    }=props



    return (
        <div className="row mt-3 justify-content-center">
            <div className={`col-md-6 text-center py-5 py-md-1 my-md-3 cursor-pointer ${selected!=="passenger"&&"opacity-6"}`} onClick={()=>{
                onChange('passenger')
            }}>
                <h3 className={`title pe-sm-5 me-sm-5 text-center text-sm-end ${inverted&&"new"}`}>
                    {t('GENERAL_passenger')}
                </h3>
            </div>
            
            <div className={`col-md-6 text-center py-5 py-md-1 my-md-3 cursor-pointer ${selected!=="driver"&&"opacity-6"}`} onClick={()=>{
                onChange('driver')
            }}>
                <h3 className={`title ps-sm-5 ms-sm-5 text-center text-sm-start ${inverted&&"new"}`}>                    
                    {t('GENERAL_drivers')}
                </h3>
            </div>
            <div className="col-6">
                <div className={`toggle-container ${inverted&&"new"} ${selected!=="passenger"&&"right"}`}>
                    <div className={`toggle-switch ${inverted&&"new"}`}></div>
                </div>
                <h3 onClick={()=>{
                    onChange(selected=="passenger"?"driver":"passenger")
                }}>
                    <i className="fa fa-switch">

                    </i>
                </h3>
            </div>
        </div>   
    );
}

export default UserTypeSwitch;
