import React,{useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';

const FormEmail = ({onSubmit=()=>{},buttonText="",loading}) => {    

    const {t} = useTranslation()

    const formInitialState = {
        "email":{            
            "name":"email",
            "value":"",            
        }
    }
    const [form, setForm] = useState(formInitialState);

    const handleSubmit = (e)=>{
        e.preventDefault()
        onSubmit(form.email.value)
    }
    const handleChange = (e)=>{
        const {
            name,
            value
        } = e.target

        setForm({
            ...form,
            [name]:{
                ...form[name],
                "value":value
            }
        })
    }

    return (        
        <form className='form-email' onSubmit={handleSubmit}>
            <div className="form-email-body">
                <div className="form-email-field-wrapper">
                    <input type="email" className="form-email-input w-input" disabled={loading} placeholder={t("CONTACT_form_email_ph")} required onChange={handleChange} {...form.email}/>
                </div>
                <div className="form-email-submit-container">
                    <input type="submit" className="form-email-submit w-button" disabled={loading} value={loading?t('GENERAL_loading'):buttonText}/>
                </div>
            </div>
        </form>        
    );
}

export default FormEmail;
