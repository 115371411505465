import React,{useState} from 'react';
import { useTranslation } from 'react-i18next';

import imgdriver from '../../../assets/features/drivers/pantalla_request.png'
import dfimg1 from '../../../assets/features/drivers/icon_contraoferta.svg'
import dfimg2 from '../../../assets/features/drivers/icon_desarrollamarca.svg'
import dfimg3 from '../../../assets/features/drivers/icon_eligepasajeros.svg'
import dfimg4 from '../../../assets/features/drivers/icon_eligetusviajes.svg'
import dfimg5 from '../../../assets/features/drivers/icon_hazmasdinero.svg'
import dfimg6 from '../../../assets/features/drivers/icon_valoracioncomunidad.svg'

import imgpassenger from '../../../assets/features/passengers/pantalla_request.png'
import pfimg1 from '../../../assets/features/passengers/icon_conductorescercanos.svg'
import pfimg2 from '../../../assets/features/passengers/icon_eligetuconductor.svg'
import pfimg3 from '../../../assets/features/passengers/icon_eligetutarifa.svg'
import pfimg4 from '../../../assets/features/passengers/icon_guardarfavoritos.svg'
import pfimg5 from '../../../assets/features/passengers/icon_unsistemajusto.svg'
import pfimg6 from '../../../assets/features/passengers/icon_valoracioncomunidad.svg'

import UserTypeSwitch from './UserTypeSwitch';

const Features = (props) => {
    const {t} = useTranslation()
    const [userType, setuserType] = useState('passenger');
    const {
        homeRef
    }=props

    const section_images  = {
        "driver":[
            imgdriver,
            dfimg1,
            dfimg2,
            dfimg3,
            dfimg4,
            dfimg5,
            dfimg6,
        ],
        "passenger":[
            imgpassenger,
            pfimg1,
            pfimg2,
            pfimg3,
            pfimg4,
            pfimg5,
            pfimg6,
        ],
    }

    return (
        <div ref={homeRef} className='section downloads-hero' name='features_section' id='features_section'>
            <div className="container py-section section-features">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-12 text-center mt-5">
                        <h3 className='heading-3'>
                            {t('FEATURES_row_title_1')}
                        </h3>
                        <p className="paragraph-2">
                            {t('FEATURES_row_description_1')}
                        </p>
                    </div>
                    <div className="col-12"></div>
                    <div className="col-md-6 col-12 text-center">
                        <h3 className='heading-3'>
                            {t('FEATURES_row_title_2')}
                        </h3>
                        <p className="paragraph-2">
                            {t('FEATURES_row_description_2')}
                        </p>
                    </div>
                </div>

                <div className="row my-5">
                    <div className="col-12">
                        <div className="divider bg-white text-center"></div>
                    </div>
                </div>

                <div className="row my-3">
                    <div className="col-12 text-center">
                        <h1 className='header'>
                            {t('TITLE_features')}
                        </h1>
                    </div>
                </div>

                <UserTypeSwitch
                    selected={userType}
                    onChange={(type)=>{
                        setuserType(type)
                    }}
                />
                
                <div className="row justify-content-center">
                    <div className="col-md-6 my-2 text-center">
                        <h3 className="heading-3">
                            {t(`FEATURES_main_${userType}_title`)}
                        </h3>
                        <p className="paragraph-2">
                            {t(`FEATURES_main_${userType}_description`)}
                        </p>
                    </div>                
                </div> 
                <div className="row align-items-top my-4">
                    <div className="col-md-3 order-1 mb-md-extra-11">
                        <div className="row">
                            <div className="col-md-12 text-center icon-and-description-container">                            
                                <div className="feature-icon-container">
                                    <img className='feature-icon' src={section_images[userType][1]} alt="" />
                                </div>
                                <h5 className='feature-headline'>
                                    {t(`FEATURES_${userType}_title_1`)}
                                </h5>
                                <p className="feature-description">
                                    {t(`FEATURES_${userType}_description_1`)}   
                                </p>
                            </div>
                            <div className="col-md-12 text-center icon-and-description-container">
                                <div className="feature-icon-container">
                                    <img className='feature-icon' src={section_images[userType][2]} alt="" />
                                </div>
                                <h5 className='feature-headline'>
                                    {t(`FEATURES_${userType}_title_2`)}
                                </h5>
                                <p className="feature-description">
                                    {t(`FEATURES_${userType}_description_2`)}   
                                </p>
                            </div>
                            <div className="col-md-12 text-center icon-and-description-container">
                                <div className="feature-icon-container">
                                    <img className='feature-icon' src={section_images[userType][3]} alt="" />
                                </div>
                                <h5 className='feature-headline'>
                                    {t(`FEATURES_${userType}_title_3`)}
                                </h5>
                                <p className="feature-description">
                                    {t(`FEATURES_${userType}_description_3`)}   
                                </p>
                            </div>
                        </div>  
                    </div>
                    <div className="col-md-6 position-relative hide-overflow order-md-2 order-last pb-5 pb-md-1">
                        <div className="abs-phone">                        
                            <img src={section_images[userType][0]} className="w-100" alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 order-3">

                        <div className="row">
                            <div className="col-md-12 text-center icon-and-description-container">
                                <div className="feature-icon-container">
                                    <img className='feature-icon' src={section_images[userType][4]} alt="" />
                                </div>
                                <h5 className='feature-headline'>
                                    {t(`FEATURES_${userType}_title_4`)}
                                </h5>
                                <p className="feature-description">
                                    {t(`FEATURES_${userType}_description_4`)}   
                                </p>
                            </div>
                            <div className="col-md-12 text-center icon-and-description-container">
                                <div className="feature-icon-container">
                                    <img className='feature-icon' src={section_images[userType][5]} alt="" />
                                </div>
                                <h5 className='feature-headline'>
                                    {t(`FEATURES_${userType}_title_5`)}
                                </h5>
                                <p className="feature-description">
                                    {t(`FEATURES_${userType}_description_5`)}   
                                </p>
                            </div>
                            <div className="col-md-12 text-center icon-and-description-container">
                                <div className="feature-icon-container">
                                    <img className='feature-icon' src={section_images[userType][6]} alt="" />
                                </div>
                                <h5 className='feature-headline'>
                                    {t(`FEATURES_${userType}_title_6`)}
                                </h5>
                                <p className="feature-description">
                                    {t(`FEATURES_${userType}_description_6`)}   
                                </p>
                            </div>
                        </div>

                    </div>    
                </div>
            </div>
                    
        </div>
    );
}

export default Features;
