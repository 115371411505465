import React,{useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import Tutorial from './tutorial/Tutorial';

import driverTutorialImg1 from './../../../assets/howworks/driver/pantalla_driver_tut_01.png'
import driverTutorialImg2 from './../../../assets/howworks/driver/pantalla_driver_tut_02.png'
import driverTutorialImg3 from './../../../assets/howworks/driver/pantalla_driver_tut_03.png'
import driverTutorialImg4 from './../../../assets/howworks/driver/pantalla_driver_tut_04.png'

import passengerTutorialImg1 from './../../../assets/howworks/passenger/pantalla_pass_tut_01.png'
import passengerTutorialImg2 from './../../../assets/howworks/passenger/pantalla_pass_tut_02.png'
import passengerTutorialImg3 from './../../../assets/howworks/passenger/pantalla_pass_tut_03.png'
import passengerTutorialImg4 from './../../../assets/howworks/passenger/pantalla_pass_tut_04.png'
import passengerTutorialImg5 from './../../../assets/howworks/passenger/pantalla_pass_tut_05.png'
import passengerTutorialImg6 from './../../../assets/howworks/passenger/pantalla_pass_tut_06.png'
import passengerTutorialImg7 from './../../../assets/howworks/passenger/pantalla_pass_tut_07.png'

import UserTypeSwitch from './UserTypeSwitch';


const HowWorks = ({homeRef}) => {
    const {t} = useTranslation()

    const elements = {
        "driver":[
            {
                index:0,
                image:driverTutorialImg1,
                title:t("HOW_IT_WORKS_driver_step_title_1"),
                description:t("HOW_IT_WORKS_driver_step_description_1")
            },
            {
                index:1,
                image:driverTutorialImg2,
                title:t("HOW_IT_WORKS_driver_step_title_2"),
                description:t("HOW_IT_WORKS_driver_step_description_2")
            },
            {
                index:2,
                image:driverTutorialImg3,
                title:t("HOW_IT_WORKS_driver_step_title_3"),
                description:t("HOW_IT_WORKS_driver_step_description_3")
            },
            {
                index:3,
                image:driverTutorialImg4,
                title:t("HOW_IT_WORKS_driver_step_title_4"),
                description:t("HOW_IT_WORKS_driver_step_description_4")
            },
        ],
        "passenger":[
            {
                index:0,
                image:passengerTutorialImg1,
                title:t('HOW_IT_WORKS_passenger_step_title_1'),
                description:t('HOW_IT_WORKS_passenger_step_description_1')
            },
            {
                index:1,
                image:passengerTutorialImg2,
                title:t('HOW_IT_WORKS_passenger_step_title_2'),
                description:t('HOW_IT_WORKS_passenger_step_description_2')
            },
            {
                index:2,
                image:passengerTutorialImg3,
                title:t('HOW_IT_WORKS_passenger_step_title_3'),
                description:t('HOW_IT_WORKS_passenger_step_description_3')
            },
            {
                index:3,
                image:passengerTutorialImg4,
                title:t('HOW_IT_WORKS_passenger_step_title_4'),
                description:t('HOW_IT_WORKS_passenger_step_description_4')
            },
            {
                index:4,
                image:passengerTutorialImg5,
                title:t('HOW_IT_WORKS_passenger_step_title_5'),
                description:t('HOW_IT_WORKS_passenger_step_description_5')
            },
            {
                index:5,
                image:passengerTutorialImg6,
                title:t('HOW_IT_WORKS_passenger_step_title_6'),
                description:t('HOW_IT_WORKS_passenger_step_description_6')
            },
            {
                index:6,
                image:passengerTutorialImg7,
                title:t('HOW_IT_WORKS_passenger_step_title_7'),
                description:t('HOW_IT_WORKS_passenger_step_description_7')
            },
        ]
    }
    const [tswitch, settswitch] = useState("passenger");
    const [tutorialStep, settutorialStep] = useState(0);
   

    return (
        <div ref={homeRef}>
            <div className="container pt-5">
                <div className="row">
                    <div className="col-12 text-center">
                        <h1 className="header dark">
                            {t('TITLE_how_it_works')}
                        </h1>
                    </div>
                </div>
                <UserTypeSwitch
                    selected={tswitch}
                    onChange={(type)=>{
                        settutorialStep(0)
                        settswitch(type)
                    }}
                    inverted
                >                    
                </UserTypeSwitch>
                <div className="row my-2 justify-content-center">
                    <div className="col-md-8 col-12 text-center">
                        <h3>{t("HOW_IT_WORKS_title")}</h3>
                        <p>{t("HOW_IT_WORKS_description")}</p>                        
                    </div>
                </div>
                <div className="row row-tutorial">
                    <div className="col-12">
                        <Tutorial
                            elements={elements[tswitch]}
                            step={tutorialStep}
                            onClickRight={()=>{
                                if(tutorialStep==elements[tswitch].length-1){
                                    settutorialStep(0)
                                }else{
                                    settutorialStep(tutorialStep+1)
                                }
                                
                            }}
                            onClickLeft={()=>{                                
                                if(tutorialStep==0){
                                    settutorialStep(elements[tswitch].length-1)
                                }else{
                                    settutorialStep(tutorialStep-1)
                                }
                            }}
                            onDotClick={(step)=>{
                                settutorialStep(step)
                            }}
                        >
                            
                        </Tutorial>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HowWorks;
