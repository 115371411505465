import React,{useEffect,useState,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha"

import c from "../../../constants";


const ContactForm = (props) => {
    const{
        onSubmit,        
    }=props
    const {t} = useTranslation();
    const captchaRef = useRef()

    const formInitialState ={
        "name":{
            "name":"name",
            "value":"",           
        },
        "email":{
            "name":"email",
            "value":"",           
        },
        "cellphone":{
            "name":"cellphone",
            "value":"",           
        },
        "message":{
            "name":"message",
            "value":"",           
        },
    }

    const [form, setform] = useState(formInitialState);

    const selectedOptionInitialState = {
        name:t('CONTACT_form_reason_ph'),
        value:""
    }

    const [selectedOption, setSelectedOption] = useState(selectedOptionInitialState);
    const [loading, setloading] = useState(false);
    

    
    const options =[
        {
            name:t('CONTACT_form_reason_ph'),
            value:""
        },        
        {
            name:t('CONTACT_form_reason_option_1'),
            value:"drivers"
        },        
        {
            name:t('CONTACT_form_reason_option_2'),
            value:"passenger-qs"
        },        
        {
            name:t('CONTACT_form_reason_option_3'),
            value:"bugs"
        },        
        {
            name:t('CONTACT_form_reason_option_4'),
            value:"questions-comments"
        },        
        {
            name:t('CONTACT_form_reason_option_5'),
            value:"invest"
        },        
        {
            name:t('CONTACT_form_reason_option_6'),
            value:"press"
        },        
    ]

    const handleSubmit = (evt)=>{
        evt.preventDefault()
        setloading(true)
        const token = captchaRef.current.getValue();
        captchaRef.current.reset();
        onSubmit(
            {   
                "token":token,
                "contact_type":selectedOption.value,
                "name":form.name.value,
                "email":form.email.value,
                "phone":form.cellphone.value,
                "message":form.message.value,
            },
            ()=>{
                setform(formInitialState)
                setSelectedOption(selectedOptionInitialState)
                setloading(false)
            },
            ()=>{
                setloading(false)
            }
        )
    }

    const handleFormChange = (evt)=>{
        const{
            name,
            value            
        } = evt.target
        
        setform({
            ...form,
            [name]:{
                ...form[name],
                "value":value
            }
        })
    }

    return (
        <div>
            <form onSubmit={(evt)=>{handleSubmit(evt)}}>
                <select name="" id="" className="w-select" value={selectedOption.value} onChange={(evt)=>{                    
                    setSelectedOption({
                        ...selectedOption,
                        "value":evt.target.value
                    })
                }} required>                    
                    {
                        
                        options.map((o)=>{
                            return <option key={o.value} value={o.value} onClick={
                                ()=>{                                    
                                    setSelectedOption(o)
                                }
                            }>
                                {o.name}
                            </option>
                        })
                    }
                </select>
                <div className="form-element-container">
                    <input placeholder={t('CONTACT_form_name_ph')} {...form.name} type="text" className='w-input text-field-2' onChange={handleFormChange} required/>
                </div>
                <div className="form-element-container">
                    <input placeholder={t('CONTACT_form_email_ph')} {...form.email} type="email" className='w-input text-field-2' onChange={handleFormChange} required/>
                </div>
                <div className="form-element-container">
                    <input placeholder={t('CONTACT_form_cellphone_ph')} {...form.cellphone} type="text" className='w-input text-field-2' onChange={handleFormChange} required/>
                </div>
                <div className="form-element-container">
                    <textarea placeholder={t('CONTACT_form_message_ph')} {...form.message}  required={true} className="w-input text-field-2 message" onChange={handleFormChange}></textarea>
                </div>
                <div className="row justify-content-center my-3">
                    <div className="col-auto">
                        <ReCAPTCHA 
                            sitekey={c.recaptcha_key}
                            ref={captchaRef}
                        />
                    </div>
                </div>
                <input type="submit" disabled={loading} value={loading?t('GENERAL_loading'):t('GENERAL_send_message')} className='button contact w-button' />
            </form>
        </div>
    );
}

export default ContactForm;
