import React from 'react';
import { useTranslation } from 'react-i18next';

import phoneHome from "./../../../assets/phone_home.png"
import downloadAndroid from "./../../../assets/btn-playstore.svg"
import downloadIos from "./../../../assets/btn-appstore.svg"


const Home = (props) => {
    const {t} = useTranslation()

    const {
        clickDownloadButton,        
    } = props

    return (
        <div className='container'>
            <div className="row justify-content-start mt-4">
                <div className="col-md-6 position-relative hide-overflow">
                    <div className="abs-phone header">                        
                        <img src={phoneHome} className="w-100" alt="" />
                    </div>
                </div>
                <div className="col-md-5 order-first order-md-last py-5 text-center">
                    <div className="row">
                        <div className="col-12">
                            <h1 className='heading text-md-start'>
                                {t('HOME_main_title')}                     
                            </h1>                 
                            <p className='mt-3 heading-text text-md-start'>
                                {t('HOME_main_description')} <br />
                                <b>
                                {t('HOME_main_description_bold')}
                                </b>
                            </p>                   
                        </div>
                    </div>
                    <div className="row justify-content-md-start justify-content-center mt-5 mb-4">
                        <div className="col-md-5 col-5 text-center">                            
                            <a className='cursor-pointer' onClick={()=>{clickDownloadButton('ios')}}>
                                <img src={downloadIos} alt="" className="mx-auto w-100" />
                            </a>
                        </div>
                        <div className="col-md-5 col-5 text-center">                            
                            <a className='cursor-pointer' onClick={()=>{clickDownloadButton('android')}}>
                                <img src={downloadAndroid} alt="" className="mx-auto w-100" />
                            </a>
                        </div>
                    </div>
                </div>               
            </div>
        </div>
    );
}

export default Home;
