import React from 'react';
import { useTranslation } from 'react-i18next';

import logo from "./../../../assets/buscaride-logo.svg"

const Navbar = (props) => {
    const {
        isMenuOpen=false,        
        onClickMenu=()=>{},
        onFeaturesClick =()=>{},
        onHowItWorks =()=>{},
        onSafety =()=>{},
        onContact=()=>{},
    } = props
    const {t} = useTranslation()

    return (
        <div className='navbar container hero-padding-top'>
            <div className="row justify-content-between align-items-center w-100">
                <div className="col-auto">
                    <a href="#" className='brand w-nav-brand'>
                        <img src={logo} alt="" className="w-100 h-100" />
                    </a>
                </div>
                <div className="col-auto d-md-none z-9999">
                    <div className={`menu-button ${isMenuOpen&&"active"}`} onClick={()=>{onClickMenu()}}>
                        <h1><i className="fa fa-bars"></i></h1>
                    </div>
                </div>
                <div className="col d-none d-md-block">
                    <div className="row justify-content-end">
                        <div className="col-md-auto">
                            <a href="#" className='w-nav-link' onClick={(evt)=>{evt.preventDefault();onFeaturesClick();}}>
                                {t('NAV_features')}                                
                            </a>                            
                        </div>
                        <div className="col-md-auto">
                            <a href="#" className='w-nav-link' onClick={(evt)=>{evt.preventDefault();onHowItWorks();}}>
                                {t('NAV_how_it_works')}                                
                            </a>  
                        </div>
                        <div className="col-md-auto">
                            <a href="#" className='w-nav-link' onClick={(evt)=>{evt.preventDefault();onSafety();}}>
                                {t('NAV_safety')}                              
                            </a>                                
                        </div>
                        <div className="col-md-auto">
                            <a href="#" className='w-nav-link' onClick={(evt)=>{evt.preventDefault();onContact();}}>
                                {t('NAV_contact')}                              
                            </a>                                
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    );
}

export default Navbar;
