import React from 'react';
import { useTranslation } from 'react-i18next';

import img1 from '../../../assets/safe/icon_video.svg'
import img2 from '../../../assets/safe/icon_heart.svg'
import img3 from '../../../assets/safe/icon_id.svg'
import img4 from '../../../assets/safe/icon_validated.svg'
import shield from '../../../assets/safe/icon_shield-alt.svg'

import logo from "./../../../assets/buscaride-logo.svg"

import downloadAndroid from "./../../../assets/btn-playstore.svg"
import downloadIos from "./../../../assets/btn-appstore.svg"




const KeepingSafe = ({
    homeRef,
    onClickIosDownload,
    onClickAndroidDownload,
}) => {
    const {t} =useTranslation()
    return (
        <div ref={homeRef} className='section keepingyousafe'>            
            <div className="bg-safe-1 position-relative py-section">
                <div className="bg-pic w-100 h-100 py-5"></div>
                <div className="container">
                    <div className="row my-5">
                        <div className="col-12">
                            <h1 className="heading text-white text-center mb-5">
                                {t('TITLE_keeping_you_safe')}
                            </h1>
                        </div>
                    </div>
                    <div className="row my-3 jusitfy-content-center">
                        <div className="col-md-6 my-1">
                            <div className="row">
                                <div className="col-auto"> 
                                    <div className="icon-container">
                                        <img src={img1} alt="" className="w-100 h-100" />
                                    </div>
                                </div>
                                <div className="col">
                                    <p className="text-white text-shadow">
                                        {t('KEEPING_YOU_SAFE_title_1')}
                                    </p>
                                </div>
                            </div>
                        </div>                    
                        <div className="col-md-6 my-1">
                            <div className="row">
                                <div className="col-auto">                                    
                                    <div className="icon-container">
                                        <img src={img2} alt="" className="w-100 h-100" />                                    
                                    </div>
                                </div>
                                <div className="col">
                                    <p className="text-white text-shadow">
                                        {t('KEEPING_YOU_SAFE_title_2')}
                                    </p>
                                </div>
                            </div>
                        </div>                    
                        <div className="col-md-6 my-1">
                            <div className="row">
                                <div className="col-auto">                                    
                                    <div className="icon-container">
                                        <img src={img3} alt="" className="w-100 h-100" />                                    
                                    </div>
                                </div>
                                <div className="col">
                                    <p className="text-white text-shadow">
                                        {t('KEEPING_YOU_SAFE_title_3')}
                                    </p>
                                </div>
                            </div>
                        </div>                    
                        <div className="col-md-6 my-1">
                            <div className="row">
                                <div className="col-auto">                                    
                                    <div className="icon-container">
                                        <img src={img4} alt="" className="w-100 h-100" />                                    
                                    </div>
                                </div>
                                <div className="col">
                                    <p className="text-white text-shadow">
                                        {t('KEEPING_YOU_SAFE_title_4')}
                                    </p>
                                </div>
                            </div>
                        </div>                    
                    </div>
                    <div className="row justify-content-center">                        
                        <div className="shield-circle">
                            <img src={shield} alt="" className="w-100 h-100" />
                        </div>                        
                    </div>
                </div>
            </div>
            <div className="bg-safe-2 section downloads-btm py-section">
                <div className="container py-3">
                    <div className="row justify-content-center">
                        <div className="col-md-6 text-white text-center">
                            <p className='no-margin'>{t('GENERAL_drivers_deserve')}</p>
                            <h6><b>{t('GENERAL_revolution')}</b></h6>
                            <h6 className='mt-5'>
                                <b>
                                    {t('GENERAL_offer_title')}
                                </b>
                            </h6>
                            <p className='no-margin'> 
                                <small>
                                {t('GENERAL_offer_info')}                                                                    
                                </small>                               
                            </p>
                            <h5 className='mt-5 text-gray-collect'><b>{t('GENERAL_download_now')}</b></h5>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                        <div className="col-md-3 col-4 text-center">                            
                            <a href="" onClick={(e)=>{e.preventDefault();onClickIosDownload()}}>
                                <img src={downloadIos} alt="" className="mx-auto w-75" />
                            </a>
                        </div>
                        <div className="col-md-3 col-4 text-center">
                            <a href="" onClick={(e)=>{e.preventDefault();onClickAndroidDownload()}}>
                                <img src={downloadAndroid} alt="" className="mx-auto w-75" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KeepingSafe;
