import React,{useState,useRef,useEffect} from 'react';
import { useTranslation,Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import logo from './../../assets/buscaride-logo.svg';


const Terms = ({showBack=true}) => {
    const {t} = useTranslation()  
    const ref = useRef(null)

    useEffect(() => {
        if(ref.current){
            ref.current.scrollIntoView()
        }
    });

    return (
        <div> 
            <div ref={ref}></div>
            {
                showBack&&<Link to="/" className='close-btn'> </Link>
            }            
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-3 col-6 py-md-4">
                        <img src={logo} alt="" className="w-100 p-4" />
                    </div>

                    <div className="col-12 pt-md-5 mt-5 text-start">

                        <h6 className=''><i>{t('TERMS_date')}</i></h6>
                        <h1 className="my-3">
                            {t('TERMS_title_1')}
                        </h1>
                        <p>
                            {t('TERMS_title_text_1')}
                        </p>
                        <h4 className="my-2">
                            {t('TERMS_title_1_subtitle_1')}
                        </h4>
                        <p>
                            {t('TERMS_title_1_subtitle_1_text_1')}
                        </p>
                        <p>
                            {t('TERMS_title_1_subtitle_1_text_2')}
                        </p>
                        <h4 className="my-2">
                            {t('TERMS_title_1_subtitle_2')}
                        </h4>
                        <p>
                            {t('TERMS_title_1_subtitle_2_text_1')}
                        </p>
                        <p>
                            {t('TERMS_title_1_subtitle_2_text_2')}
                        </p>

                    </div>

                    <div className="col-12 text-start">
                                                
                        <h1 className="my-3">
                            {t('TERMS_title_2')}
                        </h1>
                        
                        <p>
                            <Trans t={t} i18nKey={'TERMS_title_2_text_1'}>
                            </Trans>  
                        </p>                      

                    </div>

                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_3')}
                        </h1>
                        <p>
                            {t('TERMS_title_3_text_1')}
                        </p>
                        <ul className="w-100">
                            <Trans t={t} i18nKey={'TERMS_title_3_list_1'}>
                            </Trans>                              
                        </ul>         
                                      
                    </div>
                   
                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_4')}
                        </h1>
                        <p>
                            {t('TERMS_title_4_text_1')}
                        </p>
                        <h4 className="my-3">
                            {t('TERMS_title_4_subtitle_1')}
                        </h4> 
                        <p>
                            {t('TERMS_title_4_subtitle_1_text_1')}
                        </p>
                        <p>
                            {t('TERMS_title_4_subtitle_1_text_2')}
                        </p>
                        <ul className="w-100">                            
                            <Trans t={t} i18nKey={'TERMS_title_4_subtitle_1_list_1'}>
                            </Trans>                                                                                      
                        </ul>  
                        <h4 className="my-3">
                            {t('TERMS_title_4_subtitle_2')}
                        </h4> 
                        <p>
                            {t('TERMS_title_4_subtitle_2_text_1')}
                        </p>
                        <h4 className="my-3">
                            {t('TERMS_title_4_subtitle_3')}
                        </h4> 
                        <p>
                            {t('TERMS_title_4_subtitle_3_text_1')}
                        </p>                                                   
                    </div>

                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_5')}
                        </h1>
                        <p>
                            {t('TERMS_title_5_text_1')}
                        </p>
                                                
                        <ul className="w-100">
                            <Trans t={t} i18nKey={'TERMS_title_5_list_1'}>
                            </Trans>                            
                        </ul>  
                       
                        <p>
                            {t('TERMS_title_5_text_2')}
                        </p>
                        <p>
                            {t('TERMS_title_5_text_3')}
                        </p>

                    </div>
                    
                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_6')}
                        </h1>
                        <p>
                            {t('TERMS_title_6_text_1')}
                        </p>
                        <p>
                            {t('TERMS_title_6_text_2')}
                        </p>
                        <p>
                            {t('TERMS_title_6_text_3')}
                        </p>
                        <p>
                            {t('TERMS_title_6_text_4')}
                        </p>
                        <p>
                            {t('TERMS_title_6_text_5')}
                        </p>
                        <p>
                            {t('TERMS_title_6_text_6')}
                        </p>
                                                                       
                    </div>

                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_7')}
                        </h1>
                        <p>
                            {t('TERMS_title_7_text_1')}
                        </p>
                        <p>
                            {t('TERMS_title_7_text_2')}
                        </p>                      
                                                                       
                    </div>
                    
                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_8')}
                        </h1>
                        <p>
                            {t('TERMS_title_8_text_1')}
                        </p>
                        <p>
                            {t('TERMS_title_8_text_2')}
                        </p>                      
                                                                       
                    </div>

                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_9')}
                        </h1>
                        <p>
                            {t('TERMS_title_9_text_1')}
                        </p>                                          
                                                                       
                    </div>
                    
                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_10')}
                        </h1>
                        <p>
                            {t('TERMS_title_10_text_1')}
                        </p>                                          
                                                                       
                    </div>

                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_11')}
                        </h1>
                        <p>
                            {t('TERMS_title_11_text_1')}
                        </p>                                          
                                                                       
                    </div>

                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_12')}
                        </h1>
                        <p>
                            {t('TERMS_title_12_text_1')}
                        </p>                                          
                                                                       
                    </div>

                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_13')}
                        </h1>
                        <p>
                            {t('TERMS_title_13_text_1')}
                        </p>                                          
                        <p>
                            {t('TERMS_title_13_text_2')}
                        </p>                                          
                        <p>
                            {t('TERMS_title_13_text_3')}
                        </p>                                          
                        <p>
                            {t('TERMS_title_13_text_4')}
                        </p>                                          
                                                                       
                    </div>
                    
                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_14')}
                        </h1>
                        <p>
                            {t('TERMS_title_14_text_1')}
                        </p>                                          
                        <h4 className="my-3">
                            {t('TERMS_title_14_subtitle_1')}
                        </h4> 
                        <p>
                            {t('TERMS_title_14_subtitle_1_text_1')}
                        </p> 
                        <h4 className="my-3">
                            {t('TERMS_title_14_subtitle_2')}
                        </h4>                                          
                        <p>
                            {t('TERMS_title_14_subtitle_2_text_1')}
                        </p>                                          
                        <p>
                            {t('TERMS_title_14_subtitle_2_text_2')}
                        </p>                                          
                        <p>
                            {t('TERMS_title_14_subtitle_2_text_3')}
                        </p>                                          
                        <p>
                            {t('TERMS_title_14_subtitle_2_text_4')}
                        </p>                                          
                        <p>
                            {t('TERMS_title_14_subtitle_2_text_5')}
                        </p>                                          
                        <p>
                            {t('TERMS_title_14_subtitle_2_text_6')}
                        </p>                                          
                        <p>
                            {t('TERMS_title_14_subtitle_2_text_7')}
                        </p>                                          
                                                                       
                    </div>

                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_15')}
                        </h1>
                        <p>
                            {t('TERMS_title_15_text_1')}
                        </p>                                          
                        <p>
                            {t('TERMS_title_15_text_2')}
                        </p>                                          
                        <p>
                            {t('TERMS_title_15_text_3')}
                        </p>                                          
                        <p>
                            {t('TERMS_title_15_text_4')}
                        </p>                                                                                                           
                                                                       
                    </div>
                    
                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_16')}
                        </h1>
                        <p>
                            {t('TERMS_title_16_text_1')}
                        </p>                                                                                                                                                                        
                                                                       
                    </div>
                    
                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_17')}
                        </h1>
                        <p>
                            {t('TERMS_title_17_text_1')}
                        </p>                                                                                                                                                                        
                                                                       
                    </div>
                   
                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_18')}
                        </h1>
                        <p>
                            {t('TERMS_title_18_text_1')}
                        </p>                                                                                                                                                                        
                        <p>
                            {t('TERMS_title_18_text_2')}
                        </p>                                                                                                                                                                        
                        <p>
                            {t('TERMS_title_18_text_3')}
                        </p>                                                                                                                                                                        
                                                                       
                    </div>
                    
                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_19')}
                        </h1>
                        <p>
                            {t('TERMS_title_19_text_1')}
                        </p>                                                                                                                                                                        
                                                                       
                    </div>
                   
                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_20')}
                        </h1>
                        <p>
                            {t('TERMS_title_20_text_1')}
                        </p>                                                                                                                                                                        
                                                                       
                    </div>
                   
                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_21')}
                        </h1>
                        <p>
                            {t('TERMS_title_21_text_1')}
                        </p>                                                                                                                                                                        
                                                                       
                    </div>
                    
                    <div className="col-12 text-start">
                        
                        <h1 className="my-3">
                            {t('TERMS_title_22')}
                        </h1>
                        <p>
                            {t('TERMS_title_22_text_1')}
                        </p>                                                                                                                                                                        
                                                                       
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Terms;
