import React,{useState,useRef,useEffect} from 'react';

import { useDispatch } from 'react-redux';

import BecomeDriver from './components/BecomeDriver';
import Contact from './components/Contact';
import Features from './components/Features';
import Navbar from './components/Navbar';
import Home from './components/Home';
import HowWorks from './components/HowWorks';
import KeepingSafe from './components/KeepingSafe';
import MobileMenu from './components/MobileMenu';
import HowCollect from './components/HowCollect';
import Prize from './components/Prizes';
import DownloadModal from './components/DownloadModal';
import MessageModal from './components/MessageModal';
import topImage from "./../../assets/arrow-to-top.svg"

import * as generalActions from '../../actions/general.actions'

import c from '../../constants'

import { animateScroll as scroll } from 'react-scroll/modules';
import { useTranslation,Trans } from 'react-i18next';



const Main = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const [showMenu, setShowMenu] = useState(false);
    const [showDownload, setShowDownload] = useState({
        show:false,
        type:"android"
    });
    const [message, setMessage] = useState("");
    const [sendPriceMailLoading, setsendPriceMailLoading] = useState(false);
    const [contactLoading, setContactLoading] = useState(false);
    const [showTopButton, setshowTopButton] = useState(false);
    const featuresRef = useRef(null)
    const weworkRef = useRef(null)
    const contactRef = useRef(null)
    const safetyRef = useRef(null)
    const topRef = useRef(null)
    

    const scrollRef = useRef(false)
  
    const handleFeaturesClick =()=>{                    
        featuresRef.current.scrollIntoView();
    }
    const handleHowItWorks =()=>{                    
        weworkRef.current.scrollIntoView();
    }
    const handleSafety =()=>{                    
        safetyRef.current.scrollIntoView();
    }
    const handleContact =()=>{                    
        contactRef.current.scrollIntoView();
    }

    const handleSubmitContact=(data,success,error)=>{
        const {
            email,
            contact_type,
            phone,
            message,
            name,
            token
        } = data

        setContactLoading(true)
        dispatch(generalActions.validateRecaptchaToken(            
            token
        )).then(
             (response)=>{
                dispatch(generalActions.addContactMessage(
                    email,
                    contact_type,
                    phone,
                    message,
                    name
                 )).then(
                     (response)=>{
                         setContactLoading(false)
                         setMessage(t('CONTACT_form_send_message_success'))
                         success()
                     },
                     (errorResponse)=>{
                         setContactLoading(false)
                         setMessage(t('CONTACT_form_send_message_error'))
                         error()
                    }
                 )    
             },
             (errorResponse)=>{
                 setContactLoading(false)
                 setMessage(t('CONTACT_form_send_message_error'))
                 error()
             }
         )                    
    }
    const handlePricesSendMail = (email)=>{
        setsendPriceMailLoading(true)
        dispatch(generalActions.addEmail(
           email
        )).then(
            (response)=>{
                setsendPriceMailLoading(false)
                setMessage(t('GENERAL_send_list_email_success'))
            },
            (error)=>{
                setsendPriceMailLoading(false)
                setMessage(t('GENERAL_send_list_email_error'))
            }
        )
    }    

    const handleWindowScroll = (e)=>{                
        var scroll = window.pageYOffset;
        if(scroll>400&&!scrollRef.current){
            setshowTopButton(true)
        }else{
            if(scroll<400&&scrollRef.current){
                setshowTopButton(false)
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll',handleWindowScroll)
        return ()=>{            
            window.removeEventListener('scroll',handleWindowScroll)            
        }
    }, []);

    useEffect(() => {
        scrollRef.current=showTopButton
    }, [showTopButton]);

    const handleDownloadButtonClick = (value)=>{
        setShowDownload({"show":true,"type":value})
    }

    return (
        <div ref={topRef}>
            {
                showDownload.show&&
                <DownloadModal 
                    brand={showDownload.type}
                    onClose={()=>{
                        setShowDownload({...showDownload,"show":!showDownload.show})
                    }}
                />
            }
            {
                showTopButton&&
                <div className="fixed-button" onClick={()=>{topRef.current.scrollIntoView()}}>
                    <img src={topImage} alt="" className="w-100 h-100" />
                </div>
            }            
            {
                message!==""&&<MessageModal
                    onClose={()=>{setMessage("")}}
                >
                    {message}
                </MessageModal>
            }
            {
            showMenu&&<MobileMenu
                onFeaturesClick ={()=>{                    
                    featuresRef.current.scrollIntoView();
                }}
                onHowItWorks ={()=>{                    
                    weworkRef.current.scrollIntoView();
                }}
                onSafety ={()=>{                    
                    safetyRef.current.scrollIntoView();
                }}
                onContact ={()=>{                    
                    contactRef.current.scrollIntoView();
                }}
            ></MobileMenu>
            }
            <div className='position-relative'>
                <div className="bg-photo">

                </div>
                <Navbar 
                     isMenuOpen={showMenu}
                     onFeaturesClick ={handleFeaturesClick}
                     onHowItWorks ={handleHowItWorks}
                     onSafety ={handleSafety}
                     onContact={handleContact}
                     onClickMenu={()=>{                         
                        setShowMenu(!showMenu)
                     }}
                />
                <Home clickDownloadButton={(value)=>{ setShowDownload({"show":true,"type":value});}} />
            </div>
            <Features homeRef={featuresRef} />
            <BecomeDriver 
                homeRef={null} 
                onClickIosDownload={()=>{handleDownloadButtonClick("ios")}} 
                onClickAndroidDownload={()=>{handleDownloadButtonClick("android")}} />
            <HowWorks homeRef={weworkRef} />
            {/* <HowCollect /> */}
            <Prize onSendMail={handlePricesSendMail} sendMailLoading={sendPriceMailLoading}/>
            <KeepingSafe 
                homeRef={safetyRef} 
                onClickIosDownload={()=>{handleDownloadButtonClick("ios")}} 
                onClickAndroidDownload={()=>{handleDownloadButtonClick("android")}} />            
            <Contact homeRef={contactRef} onSubmitContact={handleSubmitContact} />            

        </div>
    );
}

export default Main;
