import GeneralServices from "../services/general.services";

export const updatePassword = (  
    auth,
    idUser,
    token,
    password,
    type
) => (dispatch) => {
    return GeneralServices.updatePassword(   
        auth,
        idUser,
        token,
        password,
        type
    ).then(
        (response)=>{
            return Promise.resolve(response)
        },
        (error)=>{            
            return Promise.reject()
        }
    )
}
export const validateTokenForRecovery = (  
    auth,
    idUser,
    token,
    type
) => (dispatch) => {
    return GeneralServices.validateTokenForRecovery(   
        auth,
        idUser,
        token,
        type
    ).then(
        (response)=>{
            return Promise.resolve(response)
        },
        (error)=>{            
            return Promise.reject()
        }
    )
}

export const validateProfile = (  
    auth,
    idUser,
    token,
    type
) => (dispatch) => {
    return GeneralServices.validateProfile(   
        auth,
        idUser,
        token,
        type
    ).then(
        (response)=>{
            return Promise.resolve(response)
        },
        (error)=>{            
            return Promise.reject()
        }
    )
}

export const validateRecaptchaToken = (      
    token
) => (dispatch) => {
    return GeneralServices.validateRecaptchaToken(           
        token
    ).then(
        (response)=>{
            return Promise.resolve(response)
        },
        (error)=>{            
            return Promise.reject()
        }
    )
}

export const addEmail = (  
    email,    
) => (dispatch) => {
    return GeneralServices.addEmail(   
        email,
    ).then(
        (response)=>{
            return Promise.resolve(response)
        },
        (error)=>{            
            return Promise.reject()
        }
    )
}

export const addContactMessage = (  
    email,
    contact_type,
    phone,
    message,
    name
) => (dispatch) => {
    return GeneralServices.addContactMessage(   
        email,
        contact_type,
        phone,
        message,
        name
    ).then(
        (response)=>{
            return Promise.resolve(response)
        },
        (error)=>{            
            return Promise.reject()
        }
    )
}
