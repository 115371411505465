import React,{useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {Link,useParams}  from 'react-router-dom'

import * as generalActions from '../../actions/general.actions'

import logo from './../../assets/buscaride-logo.svg';
import { useDispatch } from 'react-redux';
import PasswordForm from './components/PasswordForm';

import c from '../../constants'


const Password = ({type="passenger"}) => {   

    const dispatch = useDispatch()

    const {user,code} = useParams()    

    const [showMessage, setShowMessage] = useState(false);
    const [preLoading, setpreLoading] = useState(false);
    const [message, setMessage] = useState("");
    const {t} =  useTranslation()

    const apiValidate=()=>{
        setpreLoading(true)
        dispatch(generalActions.validateTokenForRecovery(
            c.api_key,
            parseInt(user),
            code,
            type
        )).then(
            (response)=>{
                setpreLoading(false)                
            },
            (error)=>{
                setpreLoading(false)
                setMessage(t('PASSWORD_validate_error'))
            }
        )
    }
    const apiSubmitPassword=(password)=>{
        dispatch(
            generalActions.updatePassword(
                c.api_key,
                parseInt(user),
                code,
                password,
                type
            )
        ).then(
            (response)=>{
                setMessage(t('PASSWORD_confirm'))
            },
            (response)=>{
                setMessage(t('PASSWORD_error'))
            },
        )
    }

    useEffect(()=>{        
        apiValidate()
    }, []);

    return (
        <div>
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-6 col-6 text-center mb-3">
                        <img src={logo} className='img-fluid p-5 w-100'/>
                        {preLoading? 
                            <h6><i className="fa fa-spinner fa-spin"></i></h6>
                            :
                            message==""?
                                <PasswordForm 
                                    onSubmit={apiSubmitPassword}
                                />
                            :<h2 className='text-center'>
                                {message}
                                <br />
                                <small className='font-weight-light'>
                                    <Link to={"/"}> {t('VALIDATE_to_home')}</Link>
                                </small>
                            </h2>
                        }
                    </div>  
                </div>
            </div>
        </div>
    );
}

export default Password;
