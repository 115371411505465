import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from 'react-i18next'
import HttpApi from 'i18next-http-backend';

import languageEN from '../locate/en'
import languageES from '../locate/es'

const options = {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng'
}


i18n
.use(HttpApi)
.use(LanguageDetector)
.use(initReactI18next)
.init({
    resources: {
        en: {
            translations:languageEN,
        },
        es:{
            translations:languageES  
        } 
    },
    /* default language when load the website in browser */
    //lng: "en",
    /* When react i18next not finding any language to as default in browser */
    detection: options,
    fallbackLng: "en",
    /* debugger For Development environment */
    debug: true,
    returnObjects:true,
    ns: ["translations"],
    defaultNS: "translations",
    transKeepBasicHtmlNodesFor:"",
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },
    react: {
        useSuspense: false,
        wait: true,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',        
        transKeepBasicHtmlNodesFor:['span','div','br', 'strong', 'i', 'p', 'b','small','ul','li']
    },
    backend: {        
        requestOptions: {
          cache: 'no-store',
        },
    },
})

export default i18n;