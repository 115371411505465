import React,{useState,useEffect} from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {Link,useParams}  from 'react-router-dom';

import * as generalActions from '../../actions/general.actions'

import logo from './../../assets/buscaride-logo.svg';
import c from '../../constants'


const Validation = ({validationType="passenger"}) => {       

    const dispatch = useDispatch()
    const {user,code} = useParams()

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const {t} =  useTranslation()

    const apiValidateProfile=()=>{
        setLoading(true)
        dispatch(generalActions.validateProfile(
            c.api_key,
            parseInt(user),
            code,
            validationType
        )).then(
            (response)=>{
                setLoading(false)
                setMessage(t('VALIDATE_confirm'))
            },
            (error)=>{
                setLoading(false)
                setMessage(t('VALIDATE_error'))
            }
        )
    }

    useEffect(() => {
        apiValidateProfile()
    }, []);
    
    return (
        <div>
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-auto col-6 text-center mb-3">
                        <img src={logo} className='img-fluid p-5 w-100'/>
                        {loading? 
                            <h6><i className="fa fa-spinner fa-spin"></i></h6>
                            :<h2 className='text-center'>
                                {message}
                                <br />
                                <small className='font-weight-light'>
                                    <Link to={"/"}> {t('VALIDATE_to_home')}</Link>
                                </small>
                            </h2>
                        }
                    </div>  
                </div>
            </div>
        </div>
    );
}

export default Validation;
