const constants = {
    links:{
        iosPassenger:"https://apps.apple.com/us/app/buscaride/id1618374885",
        iosDriver:"https://apps.apple.com/us/app/buscaride-drivers/id1618386146",
        androidPassenger:"https://play.google.com/store/apps/details?id=com.buscaride.passenger",
        androidDriver:"https://play.google.com/store/apps/details?id=com.buscaride.driver",
    },    
    api_driver:"https://api.driver.buscaride.com/",
    api_passenger:"https://api.passenger.buscaride.com/",
    api_landing:"https://api.crm.buscaride.com/br-crm/public/api/v1/admin/",
    //dev api_landing:"http://localhost/buscaride_crm_api/public/api/v1/admin/",
    api_key:"78Rt!0h%75/1!2uE4dk49nDj85)5nf",
    recaptcha_key:"6Lc5rBUhAAAAAIosOnBtSwKS-fbueduSlPeVpIy6",    
}

export default constants