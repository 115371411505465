import React from 'react';

const TutorialDots = (props) => {
    const {
        elements,
        step,
        onItemClick
    }=props
    return (
        <div className='tutorial-dots'>
            {elements.map(
                e=>
                <div 
                    key={e.index} 
                    className={`tutorial-button dot ${step==e.index&&"active"} mx-2`} 
                    onClick={()=>{
                    onItemClick(e.index)
                    }}
                    >                              
                </div>
            )}
        </div>
    );
}

export default TutorialDots;
