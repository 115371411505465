import React from 'react';
import { useTranslation } from 'react-i18next';
import downloadAndroid from "./../../../assets/btn-playstore-small.svg"
import downloadIos from "./../../../assets/btn-appstore-small.svg"

const BecomeDriver = ({
    onClickIosDownload,
    onClickAndroidDownload,
}) => {
    const  {t} = useTranslation()
    return (
        <div>
            <div className="container my-5 py-5">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <h1 className="header dark">
                            {t('TITLE_become_valued_driver')}
                        </h1>
                    </div>
                </div>
                <div className="row justify-content-center my-3">
                    <div className="col-10 col-sm-8 col-md col-bd">
                        <div className="bd-card h-100">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-10 text-center">
                                    <h6 className="bd-card-title">
                                        {t('BECOME_DRIVER_step_title_1')}
                                    </h6>
                                    <p className="bd-card-description">
                                        {t('BECOME_DRIVER_step_description_1')}
                                    </p>
                                </div>
                                 <div className="col-7 col-md-12 my-2 text-center">                            
                                    <a href="" onClick={(e)=>{e.preventDefault();onClickIosDownload()}}>
                                        <img src={downloadIos} alt="" className="mx-auto w-100" />
                                    </a>
                                </div>
                                <div className="col-7 col-md-12 my-2 text-center">                            
                                    <a href="" onClick={(e)=>{e.preventDefault();onClickAndroidDownload()}}>
                                        <img src={downloadAndroid} alt="" className="mx-auto w-100" />
                                    </a>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-12 d-md-none"></div>
                    <div className="col-auto col-bd">
                        <div className="bd-path"></div>
                    </div>
                    <div className="col-12 d-md-none"></div>
                    <div className="col-10 col-sm-8 col-md col-bd">
                        <div className="bd-card h-100">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-10 text-center">
                                    <h6 className="bd-card-title">
                                        {t('BECOME_DRIVER_step_title_2')}
                                    </h6>
                                    <p className="bd-card-description">
                                        {t('BECOME_DRIVER_step_description_2')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 d-md-none"></div>
                    <div className="col-auto col-bd">
                        <div className="bd-path flipped"></div>
                    </div>
                    <div className="col-12 d-md-none"></div>
                    <div className="col-10 col-sm-8 col-md col-bd">
                        <div className="bd-card h-100">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-10 text-center">
                                    <h6 className="bd-card-title">
                                        {t('BECOME_DRIVER_step_title_3')}
                                    </h6>
                                    <p className="bd-card-description">
                                        {t('BECOME_DRIVER_step_description_3')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 d-md-none"></div>
                    <div className="col-auto col-bd">
                        <div className="bd-path"></div>
                    </div>
                    <div className="col-12 d-md-none"></div>
                    <div className="col-10 col-sm-8 col-md col-bd">
                        <div className="bd-card h-100">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-10 text-center">
                                    <h6 className="bd-card-title">
                                        {t('BECOME_DRIVER_step_title_4')}
                                    </h6>
                                    <p className="bd-card-description">
                                        {t('BECOME_DRIVER_step_description_4')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    );
}

export default BecomeDriver;
