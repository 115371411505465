import React from 'react';
import { useTranslation } from 'react-i18next';


const MobileMenu = (props) => {
    const {
        onFeaturesClick =()=>{},
        onHowItWorks =()=>{},
        onSafety =()=>{},
        onContact=()=>{},
    }=props
    
    const {t} = useTranslation()

    return (
        <div className='mobile-menu'>
            <div className="container">
                <div className="row justify-content-end">
                    <div className="col-12 my-2 text-center">
                        <a className='w-nav-link' onClick={(evt)=>{evt.preventDefault();onFeaturesClick();}}>
                            {t('NAV_features')}                                
                        </a>                            
                    </div>
                    <div className="col-12 my-2 text-center">
                        <a href="#" className='w-nav-link' onClick={(evt)=>{evt.preventDefault();onHowItWorks();}}>
                            {t('NAV_how_it_works')}                                
                        </a>  
                    </div>
                    <div className="col-12 my-2 text-center">
                        <a href="#" className='w-nav-link' onClick={(evt)=>{evt.preventDefault();onSafety();}}>
                            {t('NAV_safety')}                              
                        </a>                                
                    </div>
                    <div className="col-12 my-2 text-center">
                        <a href="#" className='w-nav-link' onClick={(evt)=>{evt.preventDefault();onContact();}}>
                            {t('NAV_contact')}                              
                        </a>                                
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MobileMenu;
