import React,{useState,useRef,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useMatch } from 'react-router-dom';
import logo from '../../assets/buscaride-logo.svg'



const Privacy = ({showBack=true}) => {
    const {t} = useTranslation()    
    const ref = useRef(null)

    useEffect(() => {
        if(ref.current){
            ref.current.scrollIntoView()
        }
    });

    return (
        <div>       
            <div ref={ref}></div>  
            {
                showBack&&<Link to="/" className='close-btn'> </Link>
            }                
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-3 col-6 py-md-4">
                        <img src={logo} alt="" className="w-100 p-4" />
                    </div>    
                    <div dangerouslySetInnerHTML={{__html:t('PRIVACY_html')}}>
                    </div>                                        
                </div>
            </div>
        </div>
    );
}

export default Privacy;
