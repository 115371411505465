import React from 'react';
import { useTranslation } from 'react-i18next';
import ContactForm from './ContactForm'

import { Link } from 'react-router-dom';

import adlogo from '../../../assets/adesigns-logo.png'


const Contact = ({homeRef,onSubmitContact}) => {
    
    const {t} = useTranslation()
    return (
        <div ref={homeRef} className='section contact py-section'>
            <div className="container">
                <div className="row">
                    <div className="col-contact"> 
                        <h1 className="header dark text-center">
                            {t('TITLE_contact')}
                        </h1>                         
                        <ContactForm 
                            onSubmit={onSubmitContact}
                        />                        
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <p className="paragraph text-white text-center">
                            {t('CONTACT_hint_1')} | <Link to={'/terms-conditions'} className='a-white'>{t('GENERAL_terms_link')}</Link>
                        </p>
                        <p className="paragraph text-white text-center">
                            {t('CONTACT_hint')}
                        </p>
                    </div>
                </div>
                <a href="https://www.adrialdesigns.com/" target="_blank" className="link-design">
                    <div className='link-design-by'>{t('SITE_DESIGN_BY')}</div>
                    <img className='link-design-icon' src={adlogo} alt="ADESIGN LOGO" width={24}/>
                    <div className='link-desing-adrial'>{t('SITE_DESIGN_BY_NAME')}</div>
                </a>
            </div>
        </div>
    );
}

export default Contact;
