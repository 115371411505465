import React from 'react';

import downloadAndroidPassenger from "./../../../assets/btn-pasajero-android.svg"
import downloadAndroidDriver from "./../../../assets/btn-conductor-android.svg"

import downloadIosPassenger from "./../../../assets/btn-pasajero-ios.svg"
import downloadIosDriver from "./../../../assets/btn-conductor-ios.svg"
import { useTranslation } from 'react-i18next';

import C from '../../../constants.js';


const MessageModal = (props) => {
    const {        
        onClose,
        children
    } = props

    const {t} = useTranslation()

    return (
        <div className='modal-download-container'>
            <div className="modal-download">
                <div className="modal-download-close" onClick={()=>{onClose()}}>                    
                    X                  
                </div>                
                <div className="modal-download-body">
                    <div className="row justify-content-center">
                        <div className="col-12 my-5">
                            <p className="header-blue text-center">
                                { children }
                            </p>
                        </div>                        

                    </div>
                </div>
            </div>
        </div>
    );
}

export default MessageModal;
