import {useEffect,useRef} from 'react'
import { BrowserRouter as Router, Routes, Route, useLocation}  from 'react-router-dom'
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Main from './pages/Main'
import Validation from './pages/Validation'
import Terms from './pages/Terms'
import Privacy from './pages/Privacy';
import Password from './pages/Password';
import DataProtection from './pages/DataProtection';



function App() {

  const appRef = useRef(null)
  //const location = useLocation()
  
  useEffect(() => {
      if(appRef.current){
        appRef.current.scrollIntoView()
      }
  }, []);

  return (
    <div className="App" >
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/validateDriver/:user/:code" element={ <Validation  validationType={'driver'} /> } />
          <Route path="/validatePassenger/:user/:code" element={ <Validation validationType={'passenger'} /> } />
          <Route path="/recoverPassenger/:user/:code" element={ <Password type={'passenger'} /> } />
          <Route path="/recoverDriver/:user/:code" element={ <Password type={'driver'} /> } />
          <Route path="/terms-conditions" element={ <Terms /> } />
          <Route path="/terms-conditions-mobile" element={ <Terms showBack={false} /> } />
          <Route path="/privacy-policy" element={ <Privacy /> } />
          <Route path="/privacy-policy-mobile" element={ <Privacy showBack={false} /> } />
          <Route path="/data-protection" element={ <DataProtection /> } />
          <Route path="/data-protection-mobile" element={ <DataProtection showBack={false} /> } />
        </Routes>
      </Router>      
    </div>
  );
}

export default App;
