import { t } from 'i18next';
import React,{useState,useEffect} from 'react';

const PasswordForm = ({onSubmit}) => {

    const formInitialValue = {
        "password":{
            "name":"password",
            "value":""
        },
        "password_repeat":{
            "name":"password_repeat",
            "value":""
        }
    }

    const [form, setForm] = useState(formInitialValue);
    const [loading, setLoading] = useState(false);
    const [message, setmessage] = useState(false);

    const handleSubmit = ()=>{
        const {
            password,
            password_repeat,
        }= form
        setmessage("")
        setLoading(true)
        if(password.value!==password_repeat.value){
            setmessage(t('PASSWORD_FORM_password_error'))
            setLoading(false)
            return
        }
        onSubmit(
            password.value,            
        )
    }

    const handleChange = (event)=>{        
        const {
            name,
            value
        } = event.target

        setForm({
            ...form,
            [name]:{
                ...form[name],
                value
            }
        })

    }


    return (
        <div className='p-3'>
            <form action="" onSubmit={(e)=>{ e.preventDefault();handleSubmit() }}>
                
                <div className="form-group my-2">
                    <input name="password" value={form.password.value} onChange={handleChange} disabled={loading} className='form-control' placeholder={t('PASSWORD_FORM_password')} type="password" required/>
                </div>

                <div className="form-group my-2">
                    <input name="password_repeat" value={form.password_repeat.value} onChange={handleChange} disabled={loading} className='form-control' placeholder={t('PASSWORD_FORM_password_repeat')} type="password" required/>
                </div>

                {
                    message!==""&&<p className="text-danger">
                        {message}
                    </p>
                }
                <input className='btn btn-primary' type="submit" disabled={loading} value={loading?t('PASSWORD_FORM_loading'):t('PASSWORD_FORM_send')} />

            </form>
        </div>
    );
}

export default PasswordForm;
