import React,{useState} from 'react';

import checkCircledImg from "./../../../assets/check-circled.png"

import { useTranslation } from 'react-i18next';
import FormEmail from './FormEmail';

const Prizes = ({sendMailLoading=false,onSendMail=(email)=>{console.log(email)}}) => {
    const  {t} = useTranslation()    
    return (
        <div className='bg-gray'>            
            <div className="container py-section">                         
                {/* planes y precios */}
                <div className="row py-3">
                    <div className="col-12 text-center">
                        <h3 className='header'>{t('PLAN_section_title')}</h3>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md col-10 my-3 order-2">
                        <div className="prize-card h-100">
                            <div className="prize-card-header text-center">
                                <h3 className='prize-card-header-title'>{t('PLAN_0_name')}</h3>

                                <div className="prize-card-prize text-center align-items-center d-none">                                    
                                    <div className="col-12">
                                        <h6>
                                            {t('PLAN_0_price')}
                                        </h6>
                                        <p>
                                            <small>
                                                {t('PLAN_currency')}
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                                
                            <div className="prize-card-body">
                                <div className="row justify-content-center align-items-center h-100">
                                    <div className="col-12 text-center"> 
                                        <h6>- {t('PLAN_0_item_1')}</h6>                                                                                                                                                               
                                        <h5 className="text-blue mt-5">
                                            {t('PLAN_0_title_1')}
                                        </h5>
                                        <h6 className="text-blue mt-3">
                                            {t('PLAN_0_text_1')}
                                        </h6>
                                        <h6 className="text-blue">
                                            {t('PLAN_0_text_2')}
                                        </h6>
                                        <h6 className="text-blue">
                                            {t('PLAN_0_text_3')}
                                        </h6>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-10 my-3">
                        <div className="prize-card h-100">
                            <div className="prize-card-header text-center">
                                <h3 className='prize-card-header-title'>{t('PLAN_1_price')} {t('PLAN_currency')}</h3>

                                <div className="prize-card-prize text-center align-items-center d-none">                                    
                                    <div className="col-12">
                                        <h6>
                                            {t('PLAN_1_price')}
                                        </h6>
                                        <p>
                                            <small>
                                                {t('PLAN_currency')}
                                            </small>
                                        </p>                                        
                                    </div>
                                </div>
                            </div>
                                
                            <div className="prize-card-body">
                                <div className="row justify-content-center align-items-center h-100">
                                    <div className="col-12 text-center">
                                        <h6>- {t('PLAN_1_item_1')}</h6>
                                        <h6>- {t('PLAN_1_item_2')}</h6>
                                        <h6>- {t('PLAN_1_item_3')}</h6>
                                        <h5 className=" mt-5 text-blue">
                                            {t('PLAN_1_item_offer_title')}
                                        </h5>
                                        <h6 className="mt-3 text-blue">
                                            {t('PLAN_1_item_offer')}
                                        </h6>
                                        <div className="prize-card-check mx-auto mt-4">
                                            <img src={checkCircledImg} className="w-100 h-100" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md col-10 my-3 d-none">
                        <div className="prize-card h-100">
                            <div className="prize-card-header text-center">
                                <h3 className='prize-card-header-title pb-4'>{t('PLAN_2_name')}</h3>

                                <div className="prize-card-prize text-center align-items-center d-flex">                                    
                                    <div className="col-12">
                                        <h6>
                                            {t('PLAN_2_price')}
                                        </h6>
                                        <p>
                                            <small>
                                                {t('PLAN_currency')}
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                                
                            <div className="prize-card-body">
                                <div className="row justify-content-center align-items-end h-100">
                                    <div className="col-12 text-center">
                                        <h6>- {t('PLAN_2_item_1')}</h6>
                                        <h6>- {t('PLAN_2_item_2')}</h6>
                                        <h6>- {t('PLAN_2_item_3')}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12 text-center">
                        <h5 className='text-white no-margin mb-3 opacity-44'> <i>{t('PLAN_info')}</i></h5>
                        <h5>
                            <a href="https://stripe.com/pricing" target="_blank" className="mt-3 text-white opacity-44">{t('PLAN_link_text')}</a>
                        </h5>
                    </div>
                </div>
                <div className="row my-3 justify-content-center">
                    <div className="col-md-8 col-12 text-center mt-5 text-white">
                        <h5 className="paragraph-2">
                            {t('PLAN_email_tip')}
                        </h5>
                        <FormEmail 
                            loading={sendMailLoading}
                            onSubmit={onSendMail}
                            buttonText={t('GENERAL_join_list')}
                        />
                        <h5 className='mt-5'>                            
                            <strong>
                                {t('GENERAL_plan_offer_title')}
                            </strong>                                                        
                        </h5>
                        <h5>
                            <span>
                                {t('GENERAL_plan_offer_info')}                                                                    
                            </span>   
                        </h5>
                        <p className='no-margin mt-2'> 
                            <small>
                                <i>
                                    {t('GENERAL_spam_tip')}                                                                    
                                </i>
                            </small>                               
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Prizes;
