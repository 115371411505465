import C from "../constants"
import axios from "axios";

const API_DRIVER = C.api_driver;
const API_PASSENGER = C.api_passenger;
const API_LANDING = C.api_landing;

class GeneralServices {

    addEmail( 
        email
    ) {
        return axios
        .post(API_LANDING+"add-email",
        {
            email
        },
        {})
        .then((response) => {         
            return response;              
        });
    }
    addContactMessage( 
        email,
        contact_type,
        phone,
        message,
        name
    ) {
        return axios
        .post(API_LANDING+"add-contact-email",
        {
            email,
            contact_type,
            phone,
            message,
            name
        },
        {})
        .then((response) => {         
            return response;              
        });
    }
    updatePassword( 
        auth,
        idUser,
        token,
        password,
        type
    ) {
        return axios
        .post((type=="passenger"?API_PASSENGER:API_DRIVER) + "general/update-password-web",
        {
            auth,
            idUser,
            token,
            password
        },
        {})
        .then((response) => {         
            return response;              
        });
    }
    validateTokenForRecovery( 
        auth,
        idUser,
        token,
        type
    ) {
        return axios
        .post((type=="passenger"?API_PASSENGER:API_DRIVER) + "general/validate-token-for-recovery",
        {
            auth,
            idUser,
            token,            
        },
        {})
        .then((response) => {
            return response;              
        });
    }
    validateProfile( 
        auth,
        idUser,
        token,
        type
    ) {
        return axios
        .post((type=="passenger"?API_PASSENGER:API_DRIVER) + "general/validate-profile",
        {
            auth,
            idUser,
            token
        },
        {})
        .then((response) => {           
            return response;              
        });
    }

    validateRecaptchaToken(        
        token
    ){
        return axios
        .post(`${API_LANDING}validate-captcha`,
        {         
            token  
        },
        {})
        .then((response) => {           
            return response;              
        });
    }
}

export default new GeneralServices();